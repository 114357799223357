<template>
  <div class="loader"></div>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';
.loader {
  border-right: .25rem solid $mainColor1;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
  position: relative;
  top: -1.5625rem;
  
  &:before, &:after {
    content: '';
    width: 1.875rem;
    height: 1.875rem;
    display: block;
    position: absolute;
    top: calc(50% - 0.9375rem);
    left: calc(50% - 0.9375rem);
    border-left: 0.1875rem solid $mainColor1;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
  }
  
  &:after {
    width: 0.625rem;
    height: 0.625rem;
    top: calc(50% - 0.3125rem);
    left: calc(50% - 0.3125rem);
    border: 0;
    border-right: 0.125rem solid $mainColor1;
    animation: none;
  }
}

@keyframes spinLeft {
  from {transform:rotate(0deg);}
  to {transform:rotate(720deg);}
}

@keyframes spinRight {
  from {transform:rotate(360deg);}
  to {transform:rotate(0deg);}
}

</style>
