<template>
  <div class="content3">
    <data-table-sub-user
      :data="this.$store.state.userData.sub_user"
      :header="header"
      :body="body"
    />
  </div>
</template>

<script>
import DataTableSubUser from "@/views/components/table-components/DataTableSubUser.vue";
export default {
  name: "employeeAccounts",
  data() {
    return {
      header: [
        { header: "name", key: "first_name" },
        { header: "mail", key: "email" },
        { header: "hisLocation", key: "region" },
        { header: "checkLocation", key: "radius" },
        { header: "actions", key: "actions" },
      ],
      body: ["first_name", "email", "region", "radius"],
      pageName: "setting",
    };
  },
  components: {
    DataTableSubUser,
  },
};
</script>
