<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ type == 1 ? $t("addEmployee") : "تعديل موظف" }}</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <div v-if="!loadingData">
      <component
        :is="scrollbarTag"
        class="scroll-area--data-list-add-new"
        :settings="settings"
        :key="$vs.rtl"
      >
        <vs-alert :active="errMsg" color="danger" icon="new_releases">
          <span>{{ error_msg }}</span>
        </vs-alert>
        <!-- ADD -->
        <div class="p-6 pt-0" v-if="type == 1">
          <!-- NAME -->
          <vs-input
            :label="$t('name')"
            v-model="dataAddUser.first_name"
            v-validate="'required'"
            class="mt-5 w-full font-bold"
            name="name"
          />
          <span class="text-danger font-bold text-sm"
            ><small>{{ errors.first("name") }}</small></span
          >
          <!-- Email -->
          <vs-input
            :label="$t('mail')"
            v-model="dataAddUser.email"
            class="mt-5 w-full font-bold"
            name="email"
            v-validate="'required|email|min:3'"
          />
          <span class="text-danger font-bold text-sm"
            ><small>{{ errors.first("email") }}</small></span
          >

          <!-- Password -->
          <vs-input
            :label="$t('newPassword')"
            ref="password"
            v-model="password"
            v-validate="'required'"
            class="mt-5 w-full font-bold"
            name="password"
            type="password"
          />
          <span class="text-danger font-bold text-sm"
            ><small>{{ errors.first("password") }}</small></span
          >
          <!-- Confirm Password -->
          <vs-input
            :label="$t('returnNewPassword')"
            v-model="confirmPassword"
            v-validate="'required|confirmed:password'"
            data-vv-as="password"
            class="mt-5 font-bold w-full"
            name="confirmPassword"
            type="password"
          />
          <span class="text-danger font-bold text-sm"
            ><small>{{ errors.first("confirmPassword") }}</small></span
          >
          <div class="mt-5">
            <label>{{ $t("hisLocation") }}</label>
            <SelectInputVue
              :options="allLocations"
              v-model="dataAddUser.client_location_id"
            >
            </SelectInputVue>
          </div>
        </div>
        <!-- EDIT -->
        <div class="p-6" v-else-if="type == 2">
          <!-- NAME -->
          <vs-input
            :label="$t('name')"
            v-model="dataEditUser.first_name"
            v-validate="'required'"
            class="mt-5 font-bold w-full"
            name="name"
          />
          <span class="text-danger font-bold text-sm"
            ><small>{{ errors.first("name") }}</small></span
          >
          <!-- Password -->
          <vs-input
            :label="$t('newPassword')"
            @input="checkConfirmationPassword"
            v-model="password"
            ref="password"
            class="mt-5 font-bold w-full"
            name="password"
            type="password"
          />
          <span class="text-danger font-bold text-sm"
            ><small>{{ errors.first("password") }}</small></span
          >
          <!-- Confirm Password -->
          <vs-input
            :label="$t('returnNewPassword')"
            v-model="confirmPassword"
            v-validate="{
              required: requiredConfirmation,
              confirmed: password,
            }"
            data-vv-as="password"
            class="mt-5 font-bold w-full"
            name="confirmPassword"
            type="password"
          />
          <span class="text-danger font-bold text-sm"
            ><small>{{ errors.first("confirmPassword") }}</small></span
          >
          <!-- location -->
          <div class="mt-5">
            <label>{{ $t("hisLocation") }}</label>
            <SelectInputVue
              :default="{
                name: dataEditUser.region,
                id: dataEditUser.client_location_id,
              }"
              :edit="true"
              :options="allLocations"
              v-model="dataEditUser.client_location_id"
            >
            </SelectInputVue>
          </div>
          <!-- radius -->
          <vs-input
            :label="$t('checkLocation') + ' (م2)'"
            v-model="dataEditUser.radius"
            class="mt-5 font-bold w-full"
            name="radius"
          />

          <button class="mt-5 cancel-btn" @click="showDeleteModal()">
            <feather-icon
              icon="LockIcon"
              svgClasses="w-4 h-4"
              class="cursor-pointer"
            ></feather-icon>
            {{ $t("removeDevice") }}
          </button>
        </div>
      </component>

      <div class="flex flex-wrap items-center p-6 justify-end" slot="footer">
        <button
          v-if="!loading"
          class="mr-6 send-order"
          @click="submitData"
          style="font-family: Cairo"
        >
          {{ $t("save") }}
        </button>
        <button v-else class="mr-6 send-order">
          <SpinnerColor2 />
        </button>
        <button class="cancel-btn" @click="initValues()">اعادة تعيين</button>
      </div>
    </div>
    <div v-else class="flex items-center justify-center h-full">
      <spinner-color2 />
    </div>
    <ModalDeleteVue
      v-if="deleteModal"
      @closeModal="closeDeleteModal()"
      @actionDelete="removeToken()"
      :loadingAction="loadingRemoveToken"
      description="هل انت متأكد من حذف معرف الجهاز"
    />
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapGetters, mapState } from "vuex";
import SpinnerColor2 from "./SpinnerColor2.vue";
import notifyState from "@/mixins/notifyState";
import ModalDeleteVue from "@/layouts/components/ModalDelete.vue";
import SelectInputVue from "@/layouts/components/SelectInput.vue";

export default {
  name: "add-sub-user-data",
  mixins: [notifyState],
  props: {
    isSidebarActive: {
      type: Boolean,
    },
    type: {
      type: Number,
    },
    id: {
      type: Number,
    },
    editData: {
      default: "",
    },
  },
  components: {
    VuePerfectScrollbar,
    SpinnerColor2,
    ModalDeleteVue,
    SelectInputVue,
  },
  data() {
    return {
      dataAddUser: {
        first_name: "",
        email: "",
        client_location_id: "",
        region: "",
        radius: 50,
        client_attend_from_every_where: 0,
      },
      password: "",
      dataEditUser: {},
      loading: false,
      loadingRemoveToken: false,
      confirmPassword: "",
      radius: "",
      errMsg: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      deleteModal: false,
      client_location_id: null,
      loadingData: false,
      requiredConfirmation: false,
      allLocations: [],
    };
  },
  computed: {
    ...mapGetters("userData", ["error_msg", "success_api", "client_locations"]),
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    checkConfirmationPassword() {
      if (this.password) this.requiredConfirmation = true;
      else this.requiredConfirmation = false;
    },
  },
  methods: {
    ...mapActions("userData", [
      "addSubUser",
      "fetchUserData",
      "editSubUser",
      "revokeToken",
      "clientLocations",
    ]),
    initValues() {
      Object.keys(this.dataAddUser).forEach((key) => {
        this.dataAddUser[key] = "";
      });
      this.password = "";
      this.errMsg = false;
    },
    checkApi(name) {
      if (this.success_api == 1) {
        this.notifySuccess(name);
        this.$emit("closeSidebar");
        this.errMsg = false;
      } else {
        this.notifyFaild("faild");
        this.errMsg = true;
      }
    },
    returnRegion(data) {
      this.client_locations.map((location) => {
        location.id === this[data].client_location_id
          ? (this[data].region = location.region)
          : "";
      });
    },
    checkRegion(data) {
      if (this[data].client_location_id === 0) {
        this[data].client_attend_from_every_where = 1;
      } else {
        this[data].client_attend_from_every_where = 0;
      }
    },
    submitData() {
      this.$validator.validateAll().then(async (result) => {
        this.loading = true;
        if (result) {
          if (this.type == 1) {
            this.returnRegion("dataAddUser");
            this.checkRegion("dataAddUser");
            this.dataAddUser["password"] = this.password;
            await this.addSubUser(this.dataAddUser);
            this.checkApi("addToast");
          } else if (this.type == 2) {
            this.returnRegion("dataEditUser");
            this.checkRegion("dataEditUser");
            this.dataEditUser["password"] = this.password;
            await this.editSubUser(this.dataEditUser);
            this.checkApi("editToast");
          }
        }
        this.loading = false;
      });
    },
    showDeleteModal() {
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteModal = false;
    },
    async removeToken() {
      this.loadingRemoveToken = true;
      await this.revokeToken({ id: this.id });
      this.checkApi("deleteToast");
      this.closeDeleteModal();
      this.loadingRemoveToken = false;
    },
    filldataEditUser() {
      this.dataEditUser = { ...this.editData };
      if (this.dataEditUser.region == null)
        this.dataEditUser.region = "كل المناطق";
    },
    handleLocations() {
      this.allLocations = this.client_locations.slice();
      this.allLocations.map((location) => {
        if (location.is_fixed == 1)
          location.name = "موقع رئيسي - " + location.region;
        else location.name = "موقع فرعي - " + location.region;
      });
      this.allLocations.unshift({ id: 0, name: "كل المناطق" });
    },
  },
  async created() {
    this.loadingData = true;
    if (!this.client_locations.length) {
      await this.clientLocations();
    }
    this.handleLocations();
    this.filldataEditUser();
    this.loadingData = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
@import "@/assets/scss/vuexy/_advancedSearch.scss";

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 1rem - 2.8125rem - 5.125rem);

  &:not(.ps) {
    overflow-y: auto;
  }
}
label {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 5px;
  font-weight: bold;
}
.send-order {
  font-family: "Cairo", sans-serif;
  background-color: $mainColor1;
  color: #fff;
  width: 6rem;
  height: 2.625rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    border: 2px solid #fff;
    border-top: 2px solid #ffffff4d;
  }
}

.cancel-btn {
  font-family: "Cairo", sans-serif;
  background-color: transparent;
  color: #ea5455;
  border: 0.0625rem solid #ea5455;
  padding: 6px 12px;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  .loader {
    border: 2px solid #ea5455;
    border-radius: 50%;
    border-top: 2px solid rgba(49, 0, 111, 0.1019607843);
    width: 15px;
    height: 15px;
    transition: 0.3s ease-in-out;
  }
  &:hover {
    background: #ea5455;
    color: #fff;
    .loader {
      border-color: #fff;
      border-top-color: rgba(49, 0, 111, 0.1019607843);
    }
  }
}

.opacity-btn1 {
  background-color: gray;
  cursor: default;
}
</style>
