<template>
  <button
    :style="{ width: width, borderRadius: borderRadius }"
    class="button-vue"
    @click="action()"
  >
    <slot name="icon"></slot>
    {{ title }}
  </button>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    width: {
      default: "fit-content",
    },
    borderRadius: {
      default: "8px",
    },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
button {
  padding: 8px 14px;
  color: #fff;
  background-color: $mainColor1;
  border-radius: 8px;
  cursor: pointer;
  border: unset;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  justify-content: center;
  i,
  svg {
    color: #fff;
    height: 20px;
    width: 20px;
  }
}
</style>
