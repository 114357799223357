<template>
  <div class="loader"></div>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
      
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';
.loader {
  border: 2px solid $mainColor1;
  border-radius: 50%;
  border-top: 2px solid $mainColor2;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
