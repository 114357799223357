<template>
  <div id="data-list-list-view" class="data-list-container">
    <add-user v-if="addNewDataSidebar" :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :editData="userData" :type="type" :id="id" />
    <!-- multiple -->
    <vs-table ref="table" v-model="selected" noDataText="" :max-items="itemsPerPage" :data="data">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-end w-full mb-4">
        <!-- ADD NEW -->
        <button class="add-btn" @click="addNewData">
          <feather-icon icon="PlusIcon" class="mr-1 font-semibold" style="color: #31006F;" svgClasses="h-4 w-4" />
          {{ $t('add') }}
        </button>
      </div>

      <template slot="thead">
        <vs-th :key="index" v-for="(head, index) in header" :sort-key="head.key">{{ $t(head.header) }}</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :key="index" v-for="(td, index) in body">
              <p class="product-due_date font-medium truncate" v-if="tr.client_attend_from_every_where == 1 && td == 'region'">كل المناطق</p>
              <p class="product-due_date font-medium truncate" v-if="tr[td] != null">{{ tr[td] }}</p>
              <vs-chip class="product-sender" style="font-size: 0.5625rem;" v-else-if="tr[td] == null && tr.client_attend_from_every_where == 0">لا يوجد</vs-chip>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="popupActiveMethod(tr.id)" />
            </vs-td>
          </vs-tr>
        </tbody>

        <!-- Popup Confirmation -->
        <vs-popup class="holamundo"  :title="$t('areYouDelete')" :active.sync="popupActive" @close="triggerClose" ref="popupcontent">
          <p style="padding-bottom: 1rem;">{{ $t('willDelete') }}</p>
          <vs-button @click.stop="deleteData()" color="danger" class="mr-3" type="filled">{{ $t('accept') }}</vs-button>
          <vs-button @click="popupActive=false" color="primary" type="filled">{{ $t('cancel') }}</vs-button>
        </vs-popup>
      </template>

    </vs-table>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import EmptyData from './EmptyData.vue'
import addUser from './AddSubUserData.vue'
import SpinnerColor from './SpinnerColor.vue'

export default {
  name: 'data-table-sub-user',
  components: {
    SpinnerColor,
    EmptyData,
    addUser
  },
  props: {
    data: {
      type: Array,
    },
    header: {
      type: Array,
      required: true
    },
    body: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selected: [],
      checkSelect: false,
      itemsPerPage: 10,
      isMounted: false,
      popupActive: false,
      id: null,
      addNewDataSidebar: false,
      type: 0,
      userData: '',
      page: 1
    }
  },
  computed: {
    
  },
  watch: {

  },
  methods: {
    ...mapActions('userData', ["deleteSubUser"]),
    async deleteData () {
      this.deleteSubUser(this.id)
      this.popupActive = false
    },
    editData(user){
      this.type = 2
      this.userData = user
      this.id = user.id
      this.toggleDataSidebar(true)
    },
    triggerClose () {
      this.$refs.popupcontent.$el.children[1].children[1].scrollTop = 0
    },
    async popupActiveMethod (id){
      this.popupActive = true
      this.id = id
    },
    async addNewData(){
      this.type = 1
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/_dataTable.scss';
</style>