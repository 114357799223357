<template>
  <div class="modal" @click="closeModal()">
    <div class="modal-delete" @click.stop>
      <i
        ><svg
          width="46"
          height="50"
          viewBox="0 0 46 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32 11.5V9.7C32 7.17976 32 5.91965 31.5095 4.95704C31.0781 4.11031 30.3897 3.4219 29.543 2.99047C28.5804 2.5 27.3202 2.5 24.8 2.5H21.2C18.6798 2.5 17.4196 2.5 16.457 2.99047C15.6103 3.4219 14.9219 4.11031 14.4905 4.95704C14 5.91965 14 7.17976 14 9.7V11.5M18.5 23.875V35.125M27.5 23.875V35.125M2.75 11.5H43.25M38.75 11.5V36.7C38.75 40.4804 38.75 42.3705 38.0143 43.8144C37.3671 45.0845 36.3345 46.1171 35.0644 46.7643C33.6205 47.5 31.7304 47.5 27.95 47.5H18.05C14.2696 47.5 12.3795 47.5 10.9356 46.7643C9.66547 46.1171 8.63285 45.0845 7.98571 43.8144C7.25 42.3705 7.25 40.4804 7.25 36.7V11.5"
            stroke="#31006F"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          /></svg
      ></i>
      <p>{{ description }}</p>
      <div class="btns">
        <buttonVue
          borderRadius="50px"
          width="151px"
          v-if="!loadingAction"
          @action="actionDelete()"
          class="btn1"
          title="نعم احذف"
        />
        <button class="btn1 loading-delete" v-else>
          <spinner-color-2-vue />
        </button>
        <buttonVue
          borderRadius="50px"
          width="151px"
          @action="closeModal()"
          class="btn2"
          title="الغاء"
        />
      </div>
    </div>
  </div>
</template>

<script>
import buttonVue from "./button.vue";
import SpinnerColor2Vue from "../../views/components/table-components/SpinnerColor2.vue";
export default {
  props: {
    description: {
      default: "هل انت متأكد من الحذف",
      required: true,
    },
    loadingAction: {
      default: false,
    },
  },
  components: {
    buttonVue,
    SpinnerColor2Vue,
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    actionDelete() {
      this.$emit("actionDelete");
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
.button-vue.btn1 {
  background-color: #ee3838;
  padding: 8px 24px;
}
.loading-delete {
  height: 49px;
  background-color: #ee3838;
  border-radius: 4px;
  border: none;
  padding: 0px 12px;
  cursor: wait !important;
  .loader {
    border: 2px solid #fff;
    border-top: 2px solid rgba(49, 0, 111, 0.1019607843);
  }
}
.button-vue.btn2 {
  background-color: #fff;
  color: $mainColor1;
  border: 1px solid;
  border-radius: 4px;
}
</style>
<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
@import "@/assets/scss/vuexy/_modal.scss";
body {
  pointer-events: none;
}
.modal-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 999999;
  background-color: #fff;
  right: 50%;
  transform: translate(50%, -50%);
  top: 50%;
  padding: 24px 16px;
  border-radius: 24px;
  width: 576px;
}
i {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  background-color: #cf667933;
  border-radius: 50%;
}
p {
  font-family: Cairo;
  font-weight: 700;
  line-height: 34px;
  color: #cf6679;
  margin-bottom: 32px;
}
.btns {
  display: flex;
  gap: 16px;
}
</style>
