<template>
  <div class="custom-select" :tabindex="tabindex" v-click-outside="closeItems">
    <div
      class="selected"
      :class="{ 'selected-active': open, 'selected-disabled': disabled }"
      @click.prevent="toggleItems"
    >
      {{ selected.name }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <ul>
        <slot name="search"></slot>
        <slot name="loading"></slot>
        <li v-for="(option, i) of options" :key="i" @click="() => selectOption(option)">
          <p v-if="option.status && option.status == 2" style="text-decoration: line-through">
            {{ option.name }} ( خارج التغطية )
          </p>
          <span v-else>{{ option.name }}</span>
        </li>
        <infinite-loading v-if="paginate" @infinite="infiniteHandler">
          <div slot="no-more" class="font-bold mb-3 text-primary">لا يوجد</div>
          <div slot="no-results" class="font-bold mb-3 text-primary">لا يوجد</div>
        </infinite-loading>
      </ul>
    </div>
    <svg
      v-if="selected.id"
      @click="emptySelected"
      class="remove-icon"
      width="10"
      height="10"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 1L1 11M1 1L11 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg v-else width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.28516 1.7998L4.77087 5.31409C4.74522 5.34142 4.71424 5.36321 4.67984 5.3781C4.64544 5.39299 4.60835 5.40067 4.57087 5.40067C4.53339 5.40067 4.4963 5.39299 4.4619 5.3781C4.4275 5.36321 4.39652 5.34142 4.37087 5.31409L0.856584 1.7998"
        stroke="black"
        stroke-width="1.71429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
import notifyState from '@/mixins/notifyState';
import { mapActions, mapState } from 'vuex';

export default {
  mixins: [notifyState],
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    paginate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: () => null,
    },
  },
  data() {
    return {
      selected: { id: null, name: '' },
      open: false,
    };
  },
  methods: {
    ...mapActions('dataList', ['cancelMissionReasons']),

    closeItems() {
      this.open = false;
    },
    toggleItems() {
      if (!this.disabled) this.open = !this.open;
    },
    selectOption(option = {}) {
      if (option.status && option.status == 2) {
        this.notifyFaild('theAreaIsOutOfCoverage');
      } else {
        this.selected = option;
        this.open = false;
        this.$emit('input', option.id);
      }
    },
    infiniteHandler($state) {
      this.$emit('action', $state);
    },
    emptySelected() {
      this.selected = { id: null, name: '' };
      this.$emit('input', null);
    },
  },
  computed: {
    ...mapState('dataList', ['next_page_url_cancelation_reasons']),
  },
  mounted() {
    if (!this.edit) {
      // this.$emit("input", this.selected.id);
    } else {
      this.selected = this.options.filter(select => select.id == this.default);
      if (this.selected.length) {
        this.selected = this.selected[0];
      } else {
        this.selected = { id: null, name: '' };
      }
      this.$emit('input', this.selected.id);
    }
  },

  watch: {
    value(newVal) {
      if (newVal) {
        this.selectOption(this.options.find(opt => opt.id === newVal));
      } else {
        this.emptySelected();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';
.custom-select {
  width: 100%;
  height: 2.4375rem;
  position: relative;
}

.custom-select .selected {
  border: 0.0625rem solid #d8d6de;
  border-radius: 0.3125rem;
  padding: 0 0.75rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  background: #fff;
  color: #212121;
  cursor: pointer;
  user-select: none;
  height: inherit;
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.custom-select svg {
  top: 15px;
  right: 12px;
  position: absolute;
}
.custom-select .selected-active {
  border-color: $mainColor1;
}
.custom-select .selected-disabled {
  background: #fafafa;
  cursor: no-drop;
}
.custom-select .items {
  height: fit-content;
  max-height: 250px;
  border: 1px solid #eee;
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 5%);
  border-radius: 5px;
  background-color: #fff;
  padding: 6px 4px;
  overflow: auto;
  transform: translateY(-20px);
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  transition: 0.3s;
  display: block;
  opacity: 1;
  li {
    background: transparent;
    padding: 5px;
    width: 100%;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .inner-input {
    width: 100%;
    height: 2.4375rem;
    font-size: 12px;
    border: 0.0625rem solid #d8d6de;
    border-radius: 0.3125rem;
    padding: 0 0.75rem;
  }
}

.custom-select {
  .selectHide {
    display: none;
    opacity: 0 !important;
  }
}
</style>
